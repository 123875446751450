<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Salgs rapport</div>
          <span class="subtitle-1 light-grey"></span>
        </div>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" class="mr-3" @click="openRejectSales">
          Reject sales
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              outlined
              color="primary"
              @click="
                filtersTmp = JSON.parse(JSON.stringify(filters));
                filterDialog = true;
              "
            >
              <v-icon small>fal fa-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtrér</span>
        </v-tooltip>
      </v-card-title>

      <div class="date-range-template">
        <date-range-picker
          :value="{
            start_date: filters.start_date,
            end_date: filters.end_date
          }"
          @changePeriod="changePeriod"
          :ranges="ranges"
        ></date-range-picker>
      </div>
      <v-card-text>
        <div class="text-right total-shown" v-if="sales.length">
          {{ limit }} out of {{ totalAmount }}
        </div>
        <sales-data-table
          :items="sales"
          :loading="loading"
          pagination
          :has-less="page > 1"
          :has-more="hasMore"
          :total="total"
          :page="page"
          @changePage="getStats($event)"
        ></sales-data-table>
      </v-card-text>
    </v-card>
    <reject-sales-dialog
      @input="rejectSalesOpened = $event"
      :value="rejectSalesOpened"
    ></reject-sales-dialog>
    <v-dialog v-model="filterDialog" max-width="550px">
      <v-form @submit.prevent="filter()">
        <v-card>
          <v-card-title class="headline" primary-title>
            Filtrér rapport
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="filtersTmp.unique_key"
              label="Ordre nummer"
            ></v-text-field>
            <v-text-field
              v-model="filtersTmp.invoice_nr"
              label="Invoice nummer"
            ></v-text-field>
            <business-autocomplete
              v-model="filtersTmp.business_uuid"
              :return-object="false"
            ></business-autocomplete>
            <influencer-autocomplete
              v-model="filtersTmp.influencer_uuid"
              :return-object="false"
            ></influencer-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" @click="filter()">
              Filtrér
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import SalesDataTable from "../../moderator/data-tables/SalesDataTable";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import RejectSalesDialog from "../dialogs/RejectSalesDialog.vue";
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
import InfluencerAutocomplete from "../../autocompletes/InfluencerAutocomplete";
import { mapActions } from "vuex";
import DateRangePicker from "@/components/common/filters/DateRangePicker";

export default {
  components: {
    InfluencerAutocomplete,
    BusinessAutocomplete,
    SalesDataTable,
    DateRangePicker,
    RejectSalesDialog
  },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data() {
    const today = new Date();
    const yesterday = this.$moment(today).subtract(1, "days");
    const last7days = this.$moment(today).subtract(6, "days");
    const last30days = this.$moment(today).subtract(29, "days");
    const lastWeekStart = this.$moment(today)
      .subtract(1, "week")
      .isoWeekday(1)
      .startOf("isoweek");
    const lastWeekEnd = this.$moment(today)
      .subtract(1, "week")
      .isoWeekday(1)
      .endOf("isoweek");
    const lastMonthStart = this.$moment(today)
      .subtract(1, "month")
      .startOf("month");
    const lastMonthEnd = this.$moment(today)
      .subtract(1, "month")
      .endOf("month");
    return {
      loading: true,
      sales: [],
      filterDialog: false,
      filtersTmp: {},
      filters: {},
      page: 1,
      total: null,
      hasMore: true,
      rejectSalesOpened: false,
      limit: 50,
      totalAmount: null,
      ranges: {
        BRUGERDEFINERET: [null, null],
        "I DAG": [today, today],
        "I GÅR": [yesterday._d, yesterday._d],
        "SIDSTE UGE": [lastWeekStart._d, lastWeekEnd._d],
        "SIDSTE MÅNED": [lastMonthStart._d, lastMonthEnd._d],
        "SENESTE 7 DAGE": [last7days._d, today],
        "SENESTE 30 DAGE": [last30days._d, today]
      }
    };
  },
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    }
  },
  watch: {
    filters: {
      handler: function() {
        // Reset
        this.sales = [];
        this.page = 1;
        this.loading = false;
        this.hasMore = true;
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", ["getSales"]),
    changePeriod(event) {
      this.filters.start_date = event.start_date;
      this.filters.end_date = event.end_date;
      this.setUrlSearchFilters(this.filters);
      this.getStats(1);
    },
    openRejectSales() {
      this.rejectSalesOpened = true;
    },
    getStats(page = 1) {
      // Prevent double loading
      if (this.loading) {
        return;
      }
      this.page = page;
      this.loading = true;
      this.sales = [];

      let filters = this.cloneDeep(this.filters);
      let params = {
        ...filters,
        limit: this.limit,
        page: this.page
      };
      if (!params.business_uuid) {
        delete params["business_uuid"];
      }
      this.getSales(params).then(sales => {
        // Merge new results with current items
        sales.response.forEach(item => {
          let exists = this.sales.filter(itemTmp => itemTmp.uuid === item.uuid);

          if (exists.length === 0) {
            this.sales.push(item);
          }
        });

        // If no more items
        if (sales.length !== params.limit) {
          this.hasMore = false;
        }

        this.loading = false;
        this.total = sales.lastPage;
        this.totalAmount = sales.total;
      });
    },
    filter() {
      this.filters = this.cloneDeep(this.filtersTmp);
      this.filterDialog = false;
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();

    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment()
        .subtract(3, "days")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment()
        .add(1, "M")
        .startOf("month")
        .format("YYYY-MM-DD");
    } else {
      this.getStats();
    }
  }
};
</script>
