<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="50"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.uuid">
            <td>{{ item.created_at | utc_to_local_datetime }}</td>
            <td>{{ item.value | local_numbers }}</td>
            <td>{{ item.commission | local_numbers }}</td>
            <td>{{ item.unique_key }}</td>
            <td>
              <template v-if="item.click_type">{{ item.click_type }}</template>
              <template v-else-if="item.promotion_code">Rabat kode</template>
              <template v-else>Manuel</template>
            </td>
            <td>
              <channel-avatar
                v-if="item.click"
                :channel="item.click.influencer_channel.channel"
                :size="24"
              ></channel-avatar>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'influencer',
                  params: { id: item.influencer.uuid }
                }"
              >
                {{ item.influencer.user.full_name }}
              </router-link>
            </td>
            <td>
              <router-link
                :title="item.business.display_name"
                :to="{
                  name: 'business',
                  params: { id: item.business.uuid }
                }"
              >
                {{ item.business.display_name }}
              </router-link>
            </td>
            <td v-if="!isBusiness">
              <v-btn outlined icon color="primary" @click="setSelectedSale(item)">
                <v-icon small>fal fa-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <sales-information-dialog
      v-if="selectedSale"
      v-model="selectedSale"
      @update="updateSale($event)"
    ></sales-information-dialog>
    <v-pagination
      v-if="items.length"
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import ChannelAvatar from "../../avatars/ChannelAvatar";
import SalesInformationDialog from "../dialogs/SalesInformationDialog";
import { mapGetters } from 'vuex';

export default {
  components: { SalesInformationDialog, ChannelAvatar },
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    page: {
      type: Number
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    pagination: {
      type: Boolean,
      required: false,
      default: false
    },
    hasLess: {
      type: Boolean,
      required: false,
      default: false
    },
    hasMore: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [],
    selectedSale: null,
    selectedSaleIndex: null,
    currPage: 1
  }),
  computed: {
    ...mapGetters("core/auth", ["isBusiness"])
  },
  methods: {
    updateSale(newSale) {
      let index = this.selectedSaleIndex;
      Object.assign(this.items[index], newSale);
    },
    handlePageChange(value) {
      this.$emit("changePage", value);
    },
    setSelectedSale(sale) {
      this.selectedSaleIndex = this.items.indexOf(sale);
      this.selectedSale = sale;
    }
  },
  watch: {
    items() {
      this.currPage = this.page;
    }
  },
  mounted() {
    this.headers = [
      { text: "Salgs tidspunkt", value: "created_at", sortable: false },
      { text: "Værdi", value: "value", sortable: false },
      { text: "Kommission", value: "commission", sortable: false },
      { text: "Ordre nr.", value: "unique_key", sortable: false },
      { text: "Type", sortable: false },
      {
        text: "Kanal",
        value: "click.influencer_channel.channel.display_name",
        sortable: false
      },
      { text: "Influencer", sortable: false },
      { text: "Virksomhed", sortable: false },
      { text: "", sortable: false }
    ];
    if (this.isBusiness) {
      this.headers.splice(-1, 1);
    }
  }
};
</script>
