var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":"","items-per-page":50},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid},[_c('td',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.created_at)))]),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.value)))]),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.commission)))]),_c('td',[_vm._v(_vm._s(item.unique_key))]),_c('td',[(item.click_type)?[_vm._v(_vm._s(item.click_type))]:(item.promotion_code)?[_vm._v("Rabat kode")]:[_vm._v("Manuel")]],2),_c('td',[(item.click)?_c('channel-avatar',{attrs:{"channel":item.click.influencer_channel.channel,"size":24}}):_vm._e()],1),_c('td',[_c('router-link',{attrs:{"to":{
                name: 'influencer',
                params: { id: item.influencer.uuid }
              }}},[_vm._v(" "+_vm._s(item.influencer.user.full_name)+" ")])],1),_c('td',[_c('router-link',{attrs:{"title":item.business.display_name,"to":{
                name: 'business',
                params: { id: item.business.uuid }
              }}},[_vm._v(" "+_vm._s(item.business.display_name)+" ")])],1),(!_vm.isBusiness)?_c('td',[_c('v-btn',{attrs:{"outlined":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.setSelectedSale(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-eye")])],1)],1):_vm._e()])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1),(_vm.selectedSale)?_c('sales-information-dialog',{on:{"update":function($event){return _vm.updateSale($event)}},model:{value:(_vm.selectedSale),callback:function ($$v) {_vm.selectedSale=$$v},expression:"selectedSale"}}):_vm._e(),(_vm.items.length)?_c('v-pagination',{staticClass:"my-2",attrs:{"length":_vm.total,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }