<template>
  <v-dialog v-model="opened" width="100%" :max-width="`${maxWidth}px`">
    <v-card>
      <v-card-title
        v-if="step === 1 || step === 2"
        class="headline"
        primary-title
      >
        Find and reject
      </v-card-title>
      <v-card-title
        v-if="step === 3"
        class="headline error--text justify-center"
        primary-title
      >
        <v-icon color="error" large>fal fa-times-circle</v-icon>
      </v-card-title>
      <v-card-text v-if="step === 1">
        <v-container fluid grid-list-xl class="p-0">
          <v-layout wrap>
            <v-flex xs12 class="pb-0">
              <span class="subtitle-1 light-grey">
                Choose the business name and paste order IDs in input field.
              </span>
            </v-flex>
            <v-flex xs12 class="py-0">
              <business-autocomplete
                v-model="businessId"
                :return-object="false"
                hide-details
              ></business-autocomplete>
            </v-flex>
            <v-flex xs12 class="pt-0">
              <v-textarea
                v-model="orderIds"
                placeholder="Order IDs"
                color="primary"
                class="order-textarea"
                rows="5"
                hint="Only 1 order per new line"
                :loading="loading"
                :error="error"
                no-resize
                persistent-hint
                counter="1000"
                :counter-value="countLines"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-text v-if="step === 2">
        <v-container fluid grid-list-xl class="p-0">
          <v-layout wrap>
            <v-flex xs12 class="pb-0">
              <span class="subtitle-1 light-grey">
                Review selected sales and click reject.
              </span>
            </v-flex>
            <v-flex xs12 class="pb-0">
              <div class="subtitle-1 primary--text">
                <span class="mr-5 pl-3">Total orders</span>
                <span>{{stats[4].total + "/" + countLines(orderIds)}}</span>
              </div>
              <div>
                <v-data-table
                  :headers="statsHeaders"
                  :items="stats"
                  hide-default-footer
                >
                  <v-progress-linear
                    slot="progress"
                    color="primary"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="(item, k) in items"
                        :class="`${item.color}--text`"
                        :key="k"
                      >
                        <td>{{ item.status }}</td>
                        <td>{{ item.total }}</td>
                        <td>{{ item.commission }}</td>
                        <td class="primary--text">{{ item.description }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </div>
              <div class="d-flex">
                <v-checkbox
                  v-model="forceReject"
                  hide-details
                  :label="`Force reject approved`"
                ></v-checkbox>
              </div>
            </v-flex>
            <v-flex xs12 class="pb-0 px-0 mx-2 border-bottom status-table">
              <v-data-table
                :headers="headers"
                :items="sales"
                hide-default-footer
                :items-per-page="25"
              >
                <v-progress-linear
                  slot="progress"
                  color="primary"
                  indeterminate
                ></v-progress-linear>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.uuid">
                      <td :class="`${colorByStatus(item.status)}--text`">{{item.status}}</td>
                      <td>{{ item.unique_key }}</td>
                      <td>
                        {{ item.influencer.user.full_name }}
                      </td>
                      <td>
                        {{ item.value | local_numbers }}
                      </td>
                      <td>
                        {{ item.commission | local_numbers }}
                      </td>
                      <td>
                        {{ item.created_at | utc_to_local_datetime }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              <v-pagination
                v-model="page"
                class="my-2"
                :length="total"
                :total-visible="7"
                @input="getSalesTable"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-text v-if="step === 3">
        <v-container fluid grid-list-xl class="p-0">
          <v-layout wrap>
            <v-flex xs12 class="title text-center text--primary">
              Pick the reason of rejection
            </v-flex>
            <v-flex xs12 class="py-0">
              <v-btn class="w-100 justify-space-between mb-2" text color="primary" rounded>
                <span class="mr-3">TOTAL SALES</span>
                <span> {{ stats[4].total }}</span>
              </v-btn>
              <v-btn class="w-100 justify-space-between" text color="primary" rounded>
                <span class="mr-3">VALUE</span>
                <span> {{ stats[4].commission }} DKK</span>
              </v-btn>
            </v-flex>
            <v-flex xs12 class="pb-0">
              <v-radio-group v-model="selectedReason">
                <v-radio
                  v-for="item of reasons"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
              <div v-if="selectedReason === 'other'">
                <v-text-field
                  v-model="reasonText"
                  label="Reason"
                  color="primary"
                  name="reason"
                  v-validate="'min:3|max:200'"
                  :error-messages="errors.collect('reason')"
                  hint="reason skal minimum være 3 karakterer"
                ></v-text-field>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="step === 1">
        <v-spacer></v-spacer>
        <v-btn type="submit" color="primary" text @click="opened = false">
          Cancel
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          :disabled="!businessId || !orderIds"
          @click="getSummaryStats"
          :loading="loading"
        >
          Find
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="step === 2">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="rejectSales(false)">
          Back
        </v-btn>
        <v-btn type="submit" color="primary" @click="rejectSales(true)">
          Reject
        </v-btn>
      </v-card-actions>
      <v-card-actions class="d-flex justify-center" v-if="step === 3">
        <v-btn
          type="submit"
          text
          color="primary"
          class="mr-2"
          @click="confirmRejectSales(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          type="submit"
          color="success"
          :disabled="!canBeRejected"
          :loading="loading"
          @click="confirmRejectSales(true)"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  components: {
    BusinessAutocomplete
  },
  filters: { local_numbers, utc_to_local_datetime },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    businessId: null,
    orderIds: "",
    loading: false,
    error: false,
    step: 1,
    maxWidth: 550,
    reasonText: "",
    sales: [],
    status: {},
    page: 1,
    total: null,
    hasMore: true,
    limit: 50,
    totalAmount: null,
    stats: [
      {
        status: "Invoiced",
        total: "--",
        commission: "--",
        description: "Total number of orders to be rejected.",
        color: "error"
      },
      {
        status: "Rejected",
        total: "--",
        commission: "--",
        description: "Has already been rejected.",
        color: "error"
      },
      {
        status: "Approved",
        total: "--",
        commission: "--",
        description: "Approved orders that hasn’t yet been invoiced.",
        color: "warning"
      },
      {
        status: "New",
        total: "--",
        commission: "--",
        description: "New orders can be rejected.",
        color: "success"
      },
      {
        status: "Total",
        total: "--",
        commission: "--",
        description: "Total rejectable.",
        color: "primary"
      }
    ],
    statsHeaders: [
      { text: "Status", sortable: false },
      { text: "Number of sales", sortable: false },
      { text: "Total commission", sortable: false },
      { text: "", sortable: false }
    ],
    headers: [
      { text: "Status", sortable: false },
      { text: "Order ID", sortable: false },
      { text: "Influencer", sortable: false },
      { text: "Value", sortable: false },
      { text: "Commission", sortable: false },
      { text: "Created", sortable: false }
    ],
    reasons: [
      {
        text: "Fraud",
        value: "fraud"
      },
      {
        text: "Refund",
        value: "refund"
      },
      {
        text: "Error",
        value: "error"
      },
      {
        text: "Return",
        value: "return"
      },
      {
        text: "Other",
        value: "other"
      }
    ],
    selectedReason: "error",
    forceReject: false
  }),
  computed: {
    opened: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    canBeRejected() {
      if (this.selectedReason === "other") {
        if (this.reasonText && this.reasonText.length >= 3) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    orderNumbers() {
      // convert order numbers
      const lines = this.orderIds.split(/\r*\n/);
      const trimLines = lines.filter(item => item !== "");
      return trimLines.toString();
    }
  },
  methods: {
    ...mapActions("moderator", ["salesSummaryStatuses", "bulkRejectSales"]),
    ...mapActions("core", ["getSales"]),
    countLines(value) {
      const lines = value.split(/\r*\n/);
      const trimLines = lines.filter(item => item !== "");
      return trimLines.length;
    },
    getSummaryStats() {
      this.loading = true;
      this.error = false;
      const params = {
        business_uuid: this.businessId,
        unique_key: this.orderNumbers
      };
      this.salesSummaryStatuses(params).then(
        status => {
          this.loading = false;
          this.status = status;
          this.setStatus();
          this.getSalesTable(1);
          this.switchStep(2);
        },
        () => {
          this.loading = false;
          this.error = true;
        }
      );
    },
    getSalesTable(page = 1) {
      this.page = page;
      this.loading = true;
      this.sales = [];
      let params = {
        unique_key: this.orderNumbers,
        limit: this.limit,
        page: this.page,
        business_uuid: this.businessId
      };
      this.getSales(params).then(sales => {
        // Merge new results with current items
        sales.response.forEach(item => {
          let exists = this.sales.filter(itemTmp => itemTmp.uuid === item.uuid);
          if (exists.length === 0) {
            item.status = this.getStatus(item);
            this.sales.push(item);
          }
        });
        // If no more items
        if (sales.length !== params.limit) {
          this.hasMore = false;
        }
        this.loading = false;
        this.total = sales.lastPage;
        this.totalAmount = sales.total;
      });
    },
    confirmRejectionRequest() {
      this.loading = true;
      let params = {
        business_uuid: this.businessId,
        order_numbers: this.orderNumbers,
        disapproved_note: this.selectedReason,
        force: this.forceReject
      };
      if (this.selectedReason === "other") {
        params.disapproved_note_text = this.reasonText;
      }
      this.bulkRejectSales(params).then(
        status => {
          this.loading = false;
          this.setSnackSuccess(`Successfully rejected ${status.total} sales`);
          this.resetData();
          this.switchStep(1);
        },
        error => {
          this.loading = false;
          this.setSnackError("Bulk rejection failed");
          console.log(error);
        }
      );
    },
    getStatus(sale) {
      if (sale.approved_at !== null && sale.disapproved_at === null && sale.stripe_invoice_key === null) {
        return "approved";
      }
      if (sale.approved_at === null && sale.disapproved_at === null) {
        return "new";
      }
      if (sale.stripe_invoice_key != null) {
        return "invoiced";
      }
      if (sale.disapproved_at != null) {
        return "rejected";
      }
    },
    colorByStatus(status) {
      if (status === "invoiced") {
        return "error";
      }
      if (status === "rejected") {
        return "error";
      }
      if (status === "approved") {
        return this.forceReject ? "success" : "warning";
      }
      if (status === "new") {
        return "success";
      }
    },
    setStatus() {
      // set invoiced status
      this.stats[0].total = this.status.invoiced_count;
      this.stats[0].commission = this.status.invoiced;
      // set rejected status
      this.stats[1].total = this.status.disapproved_count;
      this.stats[1].commission = this.status.disapproved;
      // set approved status
      this.stats[2].total = this.status.approved_count;
      this.stats[2].commission = this.status.approved;
      // set new status
      this.stats[3].total = this.status.new_count;
      this.stats[3].commission = this.status.new;
      // set total status
      if (this.forceReject) {
        this.stats[4].total = this.status.new_count + this.status.approved_count;
        this.stats[4].commission = this.status.new + this.status.approved;
      } else {
        this.stats[4].total = this.status.new_count;
        this.stats[4].commission = this.status.new;
      }
    },
    rejectSales(value) {
      if (!value) {
        this.switchStep(1);
      } else {
        this.switchStep(3);
      }
    },
    confirmRejectSales(value) {
      if (!value) {
        this.switchStep(2);
      } else {
        this.confirmRejectionRequest();
      }
    },
    switchStep(step) {
      switch (step) {
        case 1:
          this.maxWidth = 400;
          break;
        case 2:
          this.maxWidth = 800;
          break;
        case 3:
          this.maxWidth = 300;
      }
      this.step = step;
    },
    resetData() {
      this.businessId = null;
      this.orderIds = "";
      this.forceReject = false;
      this.sales = [];
      this.status = {};
      this.selectedReason = "error";
      this.reasonText = "";
    }
  },
  watch: {
    forceReject(value) {
      if (value) {
        this.stats[2].color = "success";
      } else {
        this.stats[2].color = "warning";
      }
      this.setStatus();
    }
  }
};
</script>
<style lang="scss" scoped>
.status-table {
  tr:last-child {
    border-top: 1px solid #000;
  }
}
</style>
